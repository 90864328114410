.products-heading{
    margin-top: 8rem;
    padding: 0 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3rem;
    color: #3E4243;
    font-weight: 700;   
    margin-bottom: 0.8rem;
    text-transform: capitalize;
}

.products-heading::after{
    content: "";
    height: 2px;
    background-color: #F6A41D;
    width: 6rem;
    margin: 0.8rem 1rem;
}

.products-subtext{
    text-align: center;
    color: #62656A;
    overflow-wrap: break-word;
    line-height: 1.4rem;
    font-size: 1.1rem;
    padding: 0 5%;
    margin-bottom: 4rem;
}

.product-description{
    margin-top: 2rem;
}

.products-bg-1{
    background-color: #e3e4de;
    margin: 4rem 5rem;
    border-radius: 10px;
    padding: 2rem 1rem;
}

.products-bg-2{
    background-color: #fcefa7;
    margin: 4rem 5rem;
    border-radius: 10px;
    padding: 2rem 1rem;
}

.product-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 0 5rem;
    overflow: hidden;
}

.product-data h1{
    font-size: 2.2rem;
    color: #3E4243;
    font-weight: 700;    
    text-transform: capitalize;
}

.product-data p{
    color: #62656A;
    font-size: 1.2rem;
    line-height: 1.6rem;
    padding: 0.8rem 0;
}

.product-img img{
    height: 100%;
    width: 100%;
}

.catalogue-button{
    color: #4b75cb;
    width: fit-content;
    background-color: transparent;
    border: 2px solid #4b75cb;
    border-radius: 50px;
    padding: 0.7rem 1.3rem;
    margin: 0.7rem 0;
    font-size: 1rem;
    cursor: pointer; 
    transition: all 0.2s ease-in;
    outline: none;
}

.catalogue-button:hover{
    color: #fff;
    background-color: #4b75cb;
}

.catalogue-button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.2s;
}
  
.catalogue-button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.2s;
}
  
.catalogue-button:hover span {
    padding-right: 15px;
}
  
.catalogue-button:hover span:after {
    opacity: 1;
    right: 0;
}

/* For Mobile */
@media screen and (max-width: 768px){

    .products-heading{
        margin-top: 10rem;
    }
    
    .products-bg-1{
        margin: 4rem 8%;
        border-radius: 10px;
        padding: 2rem 1rem;
    }
    
    .products-bg-2{
        margin: 4rem 8%;
        border-radius: 10px;
        padding: 2rem 1rem;
    }

    .product-data{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 1rem;
    }

    .product-data p{
        font-size: 1.4rem;
        line-height: 1.6rem;
        padding: 0.8rem 0;
    }

    .catalogue-button{
        font-size: 1.2rem;
    }

}