.contact-us{
    margin: 8rem 0 5rem 0;
    padding-bottom: 2rem;
}

.contact-heading{
    margin-top: 6rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3rem;
    color: #3E4243;
    font-weight: 700;   
    margin-bottom: 0.8rem;
    text-transform: capitalize;
}

.contact-heading::after{
    content: "";
    height: 2px;
    background-color: #F6A41D;
    width: 6rem;
    margin: 0.8rem 1rem;
}

.contact-subtext{
    text-align: center;
    color: #62656A;
    overflow-wrap: break-word;
    line-height: 1.4rem;
    font-size: 1.1rem;
    padding: 0 5%;
    margin-bottom: 2rem;
}

.map{
    display: flex;
    justify-content: center;
    align-items : center;
}

.leaflet-container{
    box-shadow: 10px 10px 12px -2px rgba(0,0,0,0.25);
    border-radius: 10px;
    width:80vw;
    height:40vh;
    z-index: 0;
}

.contact-details{
    text-align: center;
    margin: 1rem 0;
}

.contact-details p {
    font-size: 1.2rem;
}

.contact-details a{
    text-decoration: none;
    color: #000;
}

.contact-details a:hover {
    color: #F6A41D;
}

