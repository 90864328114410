.scroll-button{
    cursor: pointer;
    position: fixed;
    font-size: 3rem;
    right: 1%;
    bottom: 9%;
    color: #465eb4;
}

.none{
    display: none;
}

@media screen and (max-width: 768px){
    .scroll-button{
        right: 2%;
    }
}