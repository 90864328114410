:root{
    --trans: all 0.4s linear;
}

.hero {
    background: #F8FCFF;
    position: relative;
    overflow: hidden;
    top: 0%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 5rem;
    z-index: 0;
}

.hero-bg{
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.hero-text {
    margin:0 8% 4.5rem;
    line-height: 3rem;
    color: #383535; 
    z-index: 2;   
}

.hero-text h1{
    color: #3E4243;
    font-size: 2.6rem;  
    font-weight: 900;
}

.hero-typewriter {
    color: transparent;
    font-size: 2.6rem;
    font-weight: 900;
    font-style: italic;
    -webkit-text-stroke: 2px #F6A41D;
    text-transform: uppercase;
}

.hero-text .sub-text {
    color: #62656A;
    margin-top: 1.8rem;
    font-size: 1.2rem;
    line-height: 1.5rem;
}

.avinya-button {
   color: #fff;
   background-color: #F6A41D;
   border: none;
   border-radius: 50px;
   padding: 1rem 2rem;
   margin-top: 2rem;
   font-size: 1.2rem;
   cursor: pointer; 
   transition: all 0.2s ease-in-out;
   outline: none;
}

.avinya-button:hover {
    background-color: #D46009;
    color: #fff;  
}

.line {
    width: 20px;
    height: 5px;
    border-radius: 6px;
    background-color: #F6A41D;
}

.products {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1536' height='713' preserveAspectRatio='none' viewBox='0 0 1536 713'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1533%26quot%3b)' fill='none'%3e%3cpath d='M 0%2c71 C 102.4%2c113.2 307.2%2c268.6 512%2c282 C 716.8%2c295.4 819.2%2c131.2 1024%2c138 C 1228.8%2c144.8 1433.6%2c280.4 1536%2c316L1536 713L0 713z' fill='rgba(140%2c 176%2c 254%2c 0.5)'%3e%3c/path%3e%3cpath d='M 0%2c450 C 102.4%2c483 307.2%2c625.8 512%2c615 C 716.8%2c604.2 819.2%2c376.6 1024%2c396 C 1228.8%2c415.4 1433.6%2c648.8 1536%2c712L1536 713L0 713z' fill='rgba(151%2c 181%2c 243%2c 0.5)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1533'%3e%3crect width='1536' height='713' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    padding: 3rem 0;
}

.products h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3rem;
    color: #3E4243;
    font-weight: 700;   
    margin-bottom: 0.8rem;
    text-transform: capitalize;
}

.products h1::after{
    content: "";
    height: 2px;
    background-color: #F6A41D;
    width: 6rem;
    margin: 0.8rem 1rem;
}

/* cards */
.product-cards{
    position: relative;
    margin: 3rem 8%;
}

.card{
    height: 20rem;
    max-width: 25rem;
    position: relative;
    border-radius: 10px;
    box-shadow: 10px 10px 12px -2px rgba(0,0,0,0.25);
    overflow: hidden;
}

.card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.2rem;
}

.card img{
    transform: scale(1);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: all 0.4s ease-in-out ;
}

.card-caption {  
    display: flex;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    margin: auto 0;
    background-color: rgba(0,0,0,0.5);
    border-radius: 10px;
    width: 100%;
    height: 100%;
    opacity:0;  
    transition: all 0.4s ease-in; 
    padding-left: 1rem;
    padding-right: 1rem;;
}

.card-btn {
    cursor: pointer;
    width: fit-content;
    content: "\00355";
    color: #fff;
    background-color: transparent;
    background-color: #F6A41D;
    border: none;
    padding: 0.8rem 1.6rem;
    border-radius: 50px;
    outline: none;
    transition: all 0.2s ease-in-out;   
}

.card-btn::after {
    content: "\276D";
    margin-left: 6px;
    vertical-align: middle;
}

.card-btn:hover {
    background-color: #D46009;
}

.card-caption  p, 
.card-caption button {
  transform: translateY(190px);
  transition: all 0.4s ease ; 
}

.card-title{
    color: #25282D;
    text-align: center;
    font-weight: 500;
    margin-top: 1rem;
    font-size: 1.6rem;
}

.card:hover .card-caption p, 
.card:hover .card-caption button{
    transform: translateY(0);   
}

.card:hover .card-caption {
  opacity: 1;
}

.card:hover img{
    transform: scale(1.07);    
}

.about {
  margin: 5rem 0;
  padding: 5rem 0;
  background-color: #f2f2f2;
}

.about-img {
    width: 100%;
    object-fit: contain;
}

.about-text{
    display: flex;
    flex-direction: column;
    padding: 2% 8% 2% 2%;
    overflow: hidden;
}

.about h1{
    font-size: 3rem;    
    font-weight: 700;
    color: #3E4243;
    margin-bottom: 3rem;
}

.about-text p {
    color: #62656A;
    font-size: 1.2rem;
    line-height: 1.4rem;
}

.about-btn {
    color: #F6A41D;
    background-color: transparent;
    border: 2px solid #F6A41D;
    border-radius: 50px;
    padding: 0.7rem 1.3rem;
    margin-top: 2rem;
    font-size: 1rem;
    cursor: pointer; 
    transition: all 0.2s ease-in;
    outline: none;
}

.about-btn:hover{
    color: #fff;
    background-color: #F6A41D;
}

.projects h1{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 3rem;
    color: #3E4243;
    font-weight: 700;    
    margin-bottom: 3rem;
    text-transform: capitalize;
}

.projects h1::after{
    content: "";
    height: 2px;
    background-color: #F6A41D;
    width: 6rem;
    margin: 0.8rem 1rem;
}

/* For Mobile */
@media screen and (max-width: 768px){
    .hero {
        margin-top: 4rem;
        min-height: 70vh;
        height: auto;
        background-position-y: 3rem;
        justify-content: start;
    }

    .hero-bg {
        width: 140vw;
        height: auto;
        bottom: 0;
        right: 45%;
        transform: translateX(47%)
    }

    .hero-text {    
        margin:10rem 8% 4.5rem;
        line-height: 3.5rem;
        color: #383535;    
    }

    .hero-text .sub-text {
        font-size: 1.2rem;
        line-height: 1.8rem;
    }

    .product-cards {
        row-gap: 2rem;
        margin: 2rem 2rem;  
    }  

    .about-text {
        padding-left: 8%;
        margin-top: 1rem;
        overflow:hidden;  
    }

    .about h1{
        margin-bottom: 1rem;
    }

    .about-text p {
        color: #62656A;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
}