.video-grid {
    margin: 20px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
}

.video{
    width: 23rem;
    height: 16rem;
}

.video iframe{
    height:100%;
    width:100%;    
}

@media screen and (max-width: 768px){
    .video-grid {
        margin: 20px auto;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }

    .video{
        width: 26rem;
        height: 18rem;
    }
}