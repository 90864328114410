
.photos {
    margin: 5rem 5% 4rem; 
}

.line {

    content: "";
    height: 2px;
    background-color: #F6A41D;
    width: 30rem;    
    margin: 1.2rem auto;
}

.gallery-heading {
    margin-top: 8rem;
    padding: 0 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3rem;
    color: #3E4243;
    font-weight: 700;   
    margin-bottom: 0.8rem;  
    text-transform: capitalize;
}

.gallery-heading::after{
    content: "";
    height: 2px;
    background-color: #F6A41D;
    width: 6rem;
    margin: 0.8rem 1rem;
}

.gallery-subtext{
    text-align: center;
    color: #62656A;
    overflow-wrap: break-word;
    line-height: 1.4rem;
    font-size: 1.1rem;
    padding: 0 5%;
    margin-bottom: 4rem;
}

.image{
    height: 30vh;
    object-fit: cover;
}

.image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.videos{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem ;
}

@media screen and (max-width: 768px){
    .gallery-heading {
        margin-top: 10rem;
    }

}