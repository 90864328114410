
.project-carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;   
}

.project-card {
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    align-items: center;
    margin: 0 8%;
    background-color: #fff;
    box-shadow: 6px 6px 30px -5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 6px 6px 30px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 6px 30px -5px rgba(0,0,0,0.75);
}

.img-card {
    height: 60vh;
}

.img-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel-card-text {
    background: #f1f1f1;
    padding: 1rem 1rem; 
}

.carousel-card-text p{
    font-size: 1.2rem;
}

.carousel-btn{
    color: #4b75cb;
    background-color: transparent;
    border: 2px solid #4b75cb;
    border-radius: 50px;
    padding: 0.5rem 1.2rem;
    margin-top: 1rem;
    font-size: 1rem;
    cursor: pointer; 
    transition: all 0.2s ease-in;
    outline: none;
}

.carousel-btn:hover{
    color: #fff;
    background-color: #4b75cb;
}

.right-arrow {
    position: absolute;
    border-radius: 50%;
    top: 35%;
    right: 13%;
    font-size: 4rem;
    color: #F6A41D;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.left-arrow {
    position: absolute;
    border-radius: 50%;
    top: 35%;
    left: 13%;
    font-size: 4rem;
    color: #F6A41D;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.slide.active {
opacity: 1;
}

.slide {
opacity: 0.6;
transition: all 1.2s ease-in-out;
}

@media screen and (max-width: 768px){

    .project-card {
        width: 100vw;
    }
    
    .img-card {
        height: 40vh;
    }

    .right-arrow{
        right:1%;
        font-size: 5rem;
    }

    .left-arrow{
        left:1%;
        font-size: 5rem;
    }
}