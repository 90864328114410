.footer-container {
    width: 100%;
    background-color: #081d46;
    margin-top: 5rem;
    color: #fff;
}

.footer-wrapper {
    padding: 2rem 3rem;
}

.column-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}

.column-2, .column-3 {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: center;
}

.logo-wrapper {
    display: flex;
    align-items: center;
}

.column-1 h1 {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 1.8rem;
}

.tagline {
    font-size: 1rem;
    font-weight: 500;
}

.footer-logo {
    height: 4rem;
    margin-right: 1rem;
}

.column-1 ul {
    display: flex;
    padding-top: 1rem;
}

.column-1 li {
    list-style: none;
    margin: 0 0.6rem;
} 

.footer-title {
    font-size: 1.4rem;
    color: #fff;
}

.column-2 ul, .column-3 ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 1rem;
}

.column-2 li, .column-3 li {
    color: #62656A;
    padding: 0.5rem 0;
    list-style: none;
}

.column-2 a, .column-3 a {
    text-decoration: none;
    color: #62656A;
    transition: all 0.2s ease-in-out;
}

.column-2 a:hover, .column-3 a:hover {
    color: #abafb6;
} 

.logo-wrapper {
    color: #fff;
}

.footer-icons{
    margin-right: 0.5rem;
    vertical-align: middle;
}

.social-icons{
    margin-right: 0.5rem;
    vertical-align: middle;
}

.address-icon{
    display: flex;
    
}

.footer-line {
    height: 1px;
    background-color: #f2f2f2;
    width: 100%;
}

.copyright-credits{
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
    color:#fff
}

.footer-copyright a{
    color:#fff;
    font-style: italic;
}

.footer-copyright a:hover{
    color: #F6A41D;
}

.avinya-brand{
    font-family: 'Teko', sans-serif;
    font-size: 2rem;
}

.avinya-brand-copyright{
    font-family: 'Teko', sans-serif;
    font-size: 1.5rem;
    color:#fff;
}

.credit-pipe{
    font-size: 1.6rem;
}


/* For Mobile */
@media screen and (max-width: 768px){

    .column-2, .column-3 {
        display: flex;
        flex-direction: column;
        justify-content: baseline;
        align-items: flex-start;
    }

    .footer-title {
        font-size: 1.6rem;
    }

    .column-2 a, .column-3 a {
        font-size: 1.4rem;
        line-height: 1.6rem;
    }

    .logo-wrapper {
        margin: 1rem 0;
    }
}