.login {
    margin: 10rem auto;
    text-align: center;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-label {
    margin: 10px auto;
}

.login-btn {
    margin: 10px;
    cursor: pointer;
    width: fit-content;
    color: #fff;
    background-color: transparent;
    background-color: #F6A41D;
    border: none;
    padding: 0.8rem 1.6rem;
    border-radius: 50px;
    outline: none;
    transition: all 0.2s ease-in-out;
}

.login-btn:hover {
    background-color: #D46009;
}