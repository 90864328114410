* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family:'Roboto','Poppins', sans-serif;
    -webkit-tap-highlight-color: transparent;
    /* border: 1px solid red; */
  }

a {
  text-decoration: none;
}

@media screen and (max-width: 998px) {
  html {
    font-size: 88%;
  }
  
}

@media screen and (max-width: 768px) {
  html {
    font-size: 72%;
  }
}