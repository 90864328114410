.backdrop{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
  }

  .backdrop img{
    display: block;
    max-width: 60%;
    max-height: 70%;
    box-shadow: 3px 5px 7px rgba(0,0,0,1);
  }

  .delete-btn {
    width: 50px;
    height: 50px;
    font-size: 0.7rem;
    border-radius: 50%;
    border: 2px solid red;
	  cursor: pointer;
    color: red;
    position: fixed;
    top: 100px;
    left: 50%;
    z-index: 999;
    transform: translate(-50%, 0);
  }

  @media screen and (max-width: 768px){
    
    .backdrop img{
      display: block;
      max-width: 80%;
      max-height: 100%;
      box-shadow: 3px 5px 7px rgba(0,0,0,1);
    }
  }