.img-grid{
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
}
.img-wrap{
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}

.img-wrap:hover{
  cursor:pointer;
}

.img-wrap img{
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 768px){
  .img-grid{
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}