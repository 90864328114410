.upload-form{
    margin: 30px auto 10px;
    text-align: center;
  }
  .upload-label input{
    height: 0;
    width: 0;
    opacity: 0;
  }
  .upload-label{
    display: block;
    width: 60px;
    height: 60px;
    border: 2px solid #E0A42B;
    border-radius: 50%;
    margin: 10px auto;
    line-height: 60px;
    color: #E0A42B;
    font-size: 3rem;
    transition: all 0.2s ease-in-out;
  }
  .upload-label:hover{
    background: #E0A42B;
    color: white;
    cursor: pointer;
  }
  .output{
    height: 60px;
    font-size: 0.8rem;
  }
  .error{
    color: #ff4a4a;
}

.logged-out {
  display: none;
}