.about-us {
    margin: 0 5%;
}

.about-us-heading {
    margin-top: 8rem;
    padding: 0 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3rem;
    color: #3E4243;
    font-weight: 700;   
    margin-bottom: 0.8rem;
    text-transform: capitalize;
}

.about-us-heading::after{
    content: "";
    height: 2px;
    background-color: #F6A41D;
    width: 6rem;
    margin: 0.8rem 1rem;
}

.about-us-subtext{
    text-align: center;
    color: #62656A;
    overflow-wrap: break-word;
    line-height: 1.4rem;
    font-size: 1.1rem;
    padding: 0 5%;
    margin-bottom: 4rem;
}

.about-us img {
    width: 100%;
    object-fit: contain;
    height: auto;
}

.about-us-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about-us-text p {
    color: #62656A;
    font-size: 1.2rem;
    line-height: 1.6rem;
}

/* For Mobile */
@media screen and (max-width: 768px){

    .about-us-heading {
        margin-top: 10rem;
    }

    .about-us-text p {
        font-size: 1.4rem;
        line-height: 1.6rem;
    }


}